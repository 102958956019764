import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, BoxIntro } from "../components/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControl from "@material-ui/core/FormControl"

import { Input, Label, Form, Submit } from "../components/styles"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = () => {
  const [values, setValues] = React.useState({
    name: "",
    email: "",
    message: "",
  })
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }
  const handleSubmit = e => {
    e.preventDefault()

    setIsSubmitting(true)
    setHasError(false)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => {
        setValues({
          name: "",
          email: "",
          message: "",
        })
        setIsSubmitting(false)
      })
      .catch(error => {
        setHasError(true)
        setIsSubmitting(false)
      })
  }
  return (
    <Layout>
      <SEO title="Contact" />
      <Grid container alignItems="center" style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={5}>
          <BoxIntro>
            <PageHeader variant="h1">Contact Us</PageHeader>
            <Typography>
              Feel free to reach out to us here.
            </Typography>
          </BoxIntro>
        </Grid>
        <Grid item xs={12} md={7}>
          <Form
            component="form"
            container
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={6}>
              <FormControl>
                <Label htmlFor="contactName">FULL NAME</Label>
                <Input
                  id="contactName"
                  name="name"
                  required
                  disableUnderline
                  placeholder="Mary"
                  value={values.name}
                  onChange={handleChange("name")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <Label htmlFor="contactEmail">EMAIL</Label>
                <Input
                  id="contactEmail"
                  type="email"
                  name="email"
                  required
                  disableUnderline
                  placeholder="McIntosh"
                  value={values.email}
                  onChange={handleChange("email")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Label htmlFor="contactMessage">MESSAGE</Label>
                <Input
                  id="contactMessage"
                  name="message"
                  multiline
                  rows={5}
                  required
                  disableUnderline
                  value={values.message}
                  onChange={handleChange("message")}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ padding: "0 10px", textAlign: "center" }}
            >
              <Submit
                variant="outlined"
                size="large"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={20}
                    style={{ margin: "0 10px 0 0" }}
                  />
                ) : (
                    "SEND"
                  )}
              </Submit>
              {hasError && (
                <Typography color="error" style={{ margin: "10px 0 0 0" }}>
                  Something went wrong. Try later.
                </Typography>
              )}
            </Grid>
            <input type="hidden" name="form-name" value="contact" />
          </Form>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Contact
